import Footer from "../components/Footer";
import { useEffect, useState } from "react";
import axios from "axios";
import { GetNameChoose } from "../../funcUtils";
import { useNavigate } from "react-router-dom";
import KeyboardArrowLeftOutlinedIcon from "@mui/icons-material/KeyboardArrowLeftOutlined";
import AnimatedPage from "../components/AnimatedPage";
import swal from "sweetalert";
import DatePicker from "react-datepicker";

function HistoryCasino() {
    const [isShow, setShow] = useState(false);
    const [ls, setLs] = useState(null);
    function formatDate(m) {
        new Date(m);
        const dateString =
            m.getUTCFullYear() +
            "/" +
            ("0" + (m.getMonth() + 1)).slice(-2) +
            "/" +
            ("0" + m.getDate()).slice(-2) +
            "  " +
            ("0" + m.getHours()).slice(-2) +
            ":" +
            ("0" + m.getMinutes()).slice(-2);
        return dateString;
    }
    const [history, setHistory] = useState(null);
    const [profile, setProfile] = useState(JSON.parse(localStorage.getItem("profile")));
    const [isLoading, setLoading] = useState(false);
    const [endDate, setEndDate] = useState(new Date());
    const navigate = useNavigate();
    const handleBackClick = () => {
        navigate(-1);
    };
    axios.interceptors.request.use(
        (config) => {
            const token = localStorage.getItem("user");
            if (token) {
                config.headers["Authorization"] = `Bearer ${token}`;
            }
            return config;
        },
        (error) => {
            return Promise.reject(error);
        }
    );

    useEffect(() => {
        const date = new Date();
        const year = date.getFullYear();
        const month = String(date.getMonth() + 1).padStart(2, "0");
        const day = String(date.getDate()).padStart(2, "0");
        const formattedDate = `${year}-${month}-${day}`;
        axios
            .post(`${process.env.REACT_APP_API_URL}/casino//Seamless/HistoryMember`, { date: formattedDate })
            .then((res) => {
                setHistory(res.data.data);
            })
            .catch((err) => function () {});
        axios
            .get(`${process.env.REACT_APP_API_URL}/auth/getUser`, {})
            .then((res) => {
                if (profile != res.data.data) {
                    setProfile(res.data.data);
                    localStorage.setItem("profile", JSON.stringify(res.data.data));
                }
            })
            .catch((err) => localStorage.removeItem("user"));
    }, []);
    const selectHistory = (date) => {
        if (isLoading == true) return;
        setLoading(true);
        const year = date.getFullYear();
        const month = String(date.getMonth() + 1).padStart(2, "0");
        const day = String(date.getDate()).padStart(2, "0");
        const formattedDate = `${year}-${month}-${day}`;
        axios
            .post(`${process.env.REACT_APP_API_URL}/casino/Seamless/HistoryMember`, { date: formattedDate })
            .then((res) => {
                setHistory(res.data.data);
                setLoading(false);
            })
            .then((res) => {
                setLoading(false);
            });
    };
    const gameType = (data) => {
        let a;
        switch (data.toString()) {
            case "1":
                a = "Slot";
                break;
            case "2":
                a = "Live Casino";
                break;
            case "3":
                a = "Sport Book";
                break;
            case "4":
                a = "Virtual Sport";
                break;
            case "13":
                a = "ESport";
                break;
            case "12":
                a = "Jackpot";
                break;
            case "11":
                a = "Bonus";
                break;
            case "10":
                a = "Cock Fighting";
                break;
            case "9":
                a = "Other";
                break;
            case "8":
                a = "Fishing";
                break;
            case "7":
                a = "P2P";
                break;
            case "39":
                a = "PP";
                break;
            case "6":
                a = "Qipai";
                break;
            default:
                a = data;
                break;
        }
        return a;
    };
    const productId = (data) => {
        let a;
        switch (data.toString()) {
            case "1002":
                a = "Evolution";
                break;
            case "1003":
                a = "All Bet";
                break;
            case "1004":
                a = "Big Gaming";
                break;
            case "1006":
                a = "Pragmatic";
                break;
            case "1007":
                a = "PG Soft";
                break;
            case "1009":
                a = "CQ9";
                break;
            case "1012":
                a = "SBO";
                break;
            case "1046":
                a = "IBC";
                break;
            case "1052":
                a = "Dream Gaming";
                break;
            case "1078":
                a = "CMD368";
                break;
            case "1085":
                a = "JPDB";
                break;
            case "1091":
                a = "Jili";
                break;
            default:
                a = data;
                break;
        }
        return a;
    };
    const status = (data) => {
        let a;
        switch (data.toString()) {
            case "100":
                a = "Chờ xử lý";
                break;
            case "101":
                a = "Đã giải quyết";
                break;
            case "102":
                a = "Vô hiệu hóa";
                break;
            default:
                a = data;
                break;
        }
        return a;
    };
    return (
        <>
            {isLoading ? (
                <div className="loading" style={{ maxWidth: "100%" }}>
                    <div className="loader"></div>
                </div>
            ) : null}
            <div className="title-top">
                <div className="back" onClick={handleBackClick}>
                    <KeyboardArrowLeftOutlinedIcon />
                </div>
                <h1 className="title-h1">Lịch sử game</h1>
            </div>
            <div className="main">
                {history != null ? (
                    <div className="content-history" style={{ margin: "0.2rem 0 0" }}>
                        <AnimatedPage>
                            <DatePicker
                                maxDate={new Date()}
                                selected={endDate}
                                onChange={(date) => {
                                    setEndDate(date);
                                    selectHistory(date);
                                }}
                            />
                            {history?.map((item, key) => (
                                <>
                                    <div
                                        className="item_inner"
                                        onClick={() => {
                                            setLs(item);
                                            setShow(true);
                                        }}>
                                        <div className="item_history">
                                            <div className="title_item_history">
                                                <span className="sanh">{gameType(item?.GameType)}</span>
                                                <span
                                                    className={`type_state ${
                                                        item?.Status === 100 ? "pending" : item?.Status === 101 ? "win" : item?.Status === 102 ? "lose" : "cancelled"
                                                    }`}>
                                                    {status(item?.Status)}
                                                </span>
                                            </div>
                                            <div className="id_history_sanh">{productId(item?.ProductID)}</div>
                                        </div>
                                        <div className="money_history">
                                            <span className="money">{item?.BetAmount.toLocaleString("vi-VN")}₫</span>
                                            <div className="time_choose">{formatDate(new Date(item?.CreatedOn))}</div>
                                        </div>
                                    </div>
                                </>
                            ))}
                        </AnimatedPage>
                    </div>
                ) : null}

                <Footer profile={profile} />
            </div>
        </>
    );
}
export default HistoryCasino;
