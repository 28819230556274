import Footer from "../components/Footer";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import KeyboardArrowLeftOutlinedIcon from "@mui/icons-material/KeyboardArrowLeftOutlined";
import AnimatedPage from "../components/AnimatedPage";

function RechargeMenu() {
    axios.interceptors.request.use(
        (config) => {
            const token = localStorage.getItem("user");
            if (token) {
                config.headers["Authorization"] = `Bearer ${token}`;
            }
            return config;
        },
        (error) => {
            return Promise.reject(error);
        }
    );
    const navigate = useNavigate();
    const handleBackClick = () => {
        navigate(-1);
    };
    /*useEffect(() => {
        axios
            .get(`${process.env.REACT_APP_API_URL}/auth/getUser`, {})
            .then((res) => {
                if (profile != res.data.data) {
                    setProfile(res.data.data);
                    localStorage.setItem("profile", JSON.stringify(res.data.data));
                }
            })
            .catch((err) => localStorage.removeItem("user"));
    }, []);*/
    return (
        <>
            <div className="title-top">
                <div className="back" onClick={handleBackClick}>
                    <KeyboardArrowLeftOutlinedIcon />
                </div>
                <h1 className="title-h1">Nạp tiền</h1>
            </div>
            <div className="main">
                <AnimatedPage>
                    <div className="content_profile">
                        <div style={{ color: "#fefdfd", fontSize: "0.333rem", textAlign: "left", margin: "0.5rem 0 0" }}>Chọn kênh thanh toán</div>
                        <div className="menu-recharge">
                            <div className="box-miba" onClick={() => navigate("/recharge/auto")}>
                                <div className="box-miba-left">
                                    <img src="/images/icon_credit_gold.png" alt="" />
                                </div>
                                <div className="box-miba-right">
                                    <div className="name">Nạp tiền siêu tốc</div>
                                </div>
                            </div>
                            {/*<div className="box-miba" onClick={() => navigate("/recharge/bank")}>
                                <div className="box-miba-left">
                                    <img src="/images/icon_credit_gold.png" alt="" />
                                </div>
                                <div className="box-miba-right">
                                    <div className="name">Chuyển khoản qua thẻ ngân hàng</div>
                                </div>
                            </div>
                            <div className="box-miba" onClick={() => navigate("/recharge/momo")}>
                                <div className="box-miba-left">
                                    <img src="/images/icon_momo_gold.png" alt="" style={{ transform: "scale(0.8)" }} />
                                </div>
                                <div className="box-miba-right">
                                    <div className="name">Chuyển khoản qua Momo</div>
                                </div>
                            </div>*/}
                            <div className="box-miba" onClick={() => navigate("/recharge_record")}>
                                <div className="box-miba-left">
                                    <img src="/images/icon_time_gold.png" alt="" style={{ transform: "scale(0.8)" }} />
                                </div>
                                <div className="box-miba-right">
                                    <div className="name">Lịch sử nạp</div>
                                </div>
                            </div>
                        </div>
                    </div>
                </AnimatedPage>
            </div>
            <Footer />
        </>
    );
}
export default RechargeMenu;
