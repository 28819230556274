import Footer from "../components/Footer";
import { useState, useEffect } from "react";
import axios from "axios";
import { useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import swal from "sweetalert";
import KeyboardArrowLeftOutlinedIcon from "@mui/icons-material/KeyboardArrowLeftOutlined";
import AnimatedPage from "../components/AnimatedPage";

function TransferGame() {
    const [profile, setProfile] = useState(JSON.parse(localStorage.getItem("profile")));
    const [balance, setBalance] = useState(0);
    const [sanh, setSanh] = useState(null);
    const [type, setType] = useState(null);
    const [load, setLoad] = useState(false);
    const [loading, setLoading] = useState(false);
    const navigate = useNavigate();
    const handleBackClick = () => {
        navigate(-1);
    };
    axios.interceptors.request.use(
        (config) => {
            const token = localStorage.getItem("user");
            if (token) {
                config.headers["Authorization"] = `Bearer ${token}`;
            }
            return config;
        },
        (error) => {
            return Promise.reject(error);
        }
    );
    const { register, handleSubmit } = useForm();
    useEffect(() => {
        axios.get(`${process.env.REACT_APP_API_URL}/auth/getUser`, {}).then((res) => {
            if (profile != res.data.data) {
                setProfile(res.data.data);
                localStorage.setItem("profile", JSON.stringify(res.data.data));
            }
        });
    }, []);

    const onSubmit = (data) => {
        if (load === false) {
            setLoad(true);
            const formData = {
                provider: sanh,
                type: type,
                amount: Number(data.money)
            };
            axios
                .post(`${process.env.REACT_APP_API_URL}/casino/transfer`, formData)
                .then((res) => {
                    if (res.data.data.errMsg == "SUCCESS") {
                        if (type == "1") {
                            swal("Thành công", `Chuyển quỹ ra khỏi sảnh ${sanh} thành công!`, "success");
                        } else {
                            swal("Thành công", `Chuyển quỹ vào sảnh ${sanh} thành công!`, "success");
                        }
                        axios.post(`${process.env.REACT_APP_API_URL}/casino/balance`, { provider: sanh }).then((res) => {
                            if (res.data.data.errMsg == "SUCCESS") {
                                setBalance(res.data.data.balance);
                            } else {
                                window.location.reload();
                            }
                        });
                        setLoad(false);
                    } else {
                        swal("Đã xảy ra lỗi", "errCode: " + res.data.data.errCode + "\nerrMsg: " + res.data.data.errMsg, "error");
                        setLoad(false);
                    }
                })
                .catch((err) => {
                    swal("Thất bại", err.response.data.message != "internal server error" ? err.response.data.message : "Đã xảy ra lỗi", "error");
                    setLoad(false);
                });
        }
    };
    const handleChange = (e) => {
        setLoading(true);
        setSanh(e.target.value);
        axios.post(`${process.env.REACT_APP_API_URL}/casino/balance`, { provider: e.target.value }).then((res) => {
            if ((res.data.data.errMsg).toUpperCase() == "SUCCESS") {
                setBalance(res.data.data.balance);
                setLoading(false);
            } else {
                setBalance(0);
                setLoading(false);
                swal("Đã xảy ra lỗi", "errCode: " + res.data.data.errCode + "\nerrMsg: " + res.data.data.errMsg, "error").then(() => window.location.reload());
            }
        });
    };
    return (
        <>
            {loading === true ? (
                <div className="loading">
                    <div className="loader"></div>
                </div>
            ) : null}
            <div className="title-top">
                <div className="back" onClick={handleBackClick}>
                    <KeyboardArrowLeftOutlinedIcon />
                </div>
                <h1 className="title-h1">Chuyển tiền vào sảnh</h1>
            </div>
            <div className="main">
                <AnimatedPage>
                    <div className="content_profile">
                        <div style={{ color: "#eee", marginTop: "20px" }}>
                            Số dư tài khoản: <b>{profile?.money?.toLocaleString('vi-VN')}</b>
                        </div>
                        <form className="form-lg" onSubmit={handleSubmit(onSubmit)}>
                            <select onChange={handleChange} style={{ padding: "10px 20px", fontSize: "18px" }}>
                                <option hidden>Chọn sảnh</option>
                                <option value="IB">Sabah</option>
                                <option value="JJ">Jili</option>
                                <option value="AG">AG</option>
                                <option value="WC">WM</option>
                                <option value="V8">V8</option>
                                <option value="DG">DG</option>
                                <option value="EG">EG</option>
                                <option value="AT">Allbet</option>
                                <option value="TP">TP</option>
                                <option value="S2">Sexy casino</option>
                                <option value="KY">KY</option>
                                <option value="MG">MG</option>
                                <option value="GB">BBIN</option>
                                <option value="SO">SBO</option>
                                <option value="UG">UG</option>
                                <option value="CM">CMD</option>
                            </select>
                            {sanh && (
                                <>
                                    <div style={{ color: "#fff", fontSize: "16px", margin: "20px 0 10px" }}>Số dư: {balance?.toLocaleString('vi-VN')}</div>
                                    <div style={{ display: "flex", color: "#dbe3ff", justifyContent: "space-around", marginBottom: "10px" }}>
                                        <div>
                                            <input type="radio" name="type_money" value="0" onChange={(e) => setType("0")} />
                                            Chuyển quỹ vào
                                        </div>
                                        <div>
                                            <input type="radio" name="type_money" value="1" onChange={(e) => setType("1")} />
                                            Chuyển quỹ ra
                                        </div>
                                    </div>
                                    <input className="ipadd" type="number" {...register("money", { required: true })} placeholder="Nhập số tiền" />
                                    <button type="submit" className={load === true ? "btn-submit loader" : "btn-submit"}>
                                        Xác nhận
                                    </button>
                                </>
                            )}
                        </form>
                    </div>
                </AnimatedPage>

                <Footer />
            </div>
        </>
    );
}
export default TransferGame;
