import { Link, useNavigate } from "react-router-dom";
import Footer from "../components/Footer";
import Header from "../components/Header";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/swiper.min.css";
import SwiperCore, { Autoplay } from "swiper/core";
import { useEffect, useState } from "react";
import axios from "axios";
import CampaignIcon from "@mui/icons-material/Campaign";
import CloseIcon from "@mui/icons-material/Close";
import AnimatedPage from "../components/AnimatedPage";
import swal from "sweetalert";

function Home() {
    SwiperCore.use([Autoplay]);
    const swiperParams = {
        autoplay: {
            delay: 3000,
            disableOnInteraction: false
        }
    };
    const swiperMenu = {
        autoplay: false,
        slidesPerView: 7.3,
        centerInsufficientSlides: true,
        spaceBetween: 0,
        grabCursor: true
    };
    const swiperGame = {
        autoplay: false,
        slidesPerView: 2,
        centeredSlides: true,
        centeredSlidesBounds: true,
        spaceBetween: 15,
        grabCursor: true
    };
    const swiperGame2 = {
        autoplay: false,
        slidesPerView: 2.5,
        centerInsufficientSlides: true,
        spaceBetween: 20,
        grabCursor: true
    };
    const swiperGame3 = {
        autoplay: false,
        slidesPerView: 3,
        centeredSlides: true,
        centeredSlidesBounds: true,
        spaceBetween: 10,
        grabCursor: true
    };
    const searchParams = new URLSearchParams(window.location.search);
    const notifyParam = searchParams.get("notify");
    axios.interceptors.request.use(
        (config) => {
            const token = localStorage.getItem("user");
            if (token) {
                config.headers["Authorization"] = `Bearer ${token}`;
            }
            return config;
        },
        (error) => {
            return Promise.reject(error);
        }
    );
    const [profile, setProfile] = useState(JSON.parse(localStorage.getItem("profile")));
    const [notify, setNotify] = useState(JSON.parse(localStorage.getItem("notify")));
    const [bet, setBet] = useState(JSON.parse(localStorage.getItem("xsmb")));
    const [loadGame, setLoadGame] = useState(false);
    const [isLoading, setLoading] = useState(false);
    const [isShow, setShow] = useState(true);
    const [siboGame, setSiboGame] = useState(false);
    const [newMoney, setNewMoney] = useState(null);
    const [balance, setBalance] = useState(0);
    const [type, setType] = useState(1);
    const handleType = (option) => {
        setType(option);
    };
    const navigate = useNavigate();
    const getProfile = () => {
        axios
            .get(`${process.env.REACT_APP_API_URL}/auth/getUser`, {})
            .then((res) => {
                if (profile != res.data.data) {
                    setProfile(res.data.data);
                    localStorage.setItem("profile", JSON.stringify(res.data.data));
                }
            })
            .catch((err) => localStorage.removeItem("user"));
    };
    useEffect(() => {
        getProfile();
        axios.get(`${process.env.REACT_APP_API_URL}/auth/getnotify`, {}).then((res) => {
            if (notify != res.data.data) {
                setNotify(res.data.data);
                localStorage.setItem("notify", JSON.stringify(res.data.data));
            }
        });
        axios.get(`https://mu88.live/api/front/open/lottery/history/list/1/miba`).then((res) => {
            setBet(res.data.t);
            if (bet != res.data.t) {
                setBet(res.data.t);
                localStorage.setItem("xsmb", JSON.stringify(res.data.t));
            }
        });
        axios.get(`${process.env.REACT_APP_API_URL}/casino/create`);
    }, []);
    const [activeOption, setActiveOption] = useState("1");
    const handleOptionClick = (option) => {
        setActiveOption(option);
    };
    const date0 = new Date();
    const currentHour = date0.getHours();
    const [dateMT, setDateMT] = useState(date0.getDay());
    const [dataMT, setDataMT] = useState();
    const [imageMT, setImageMT] = useState([]);
    const [dateMN, setDateMN] = useState(date0.getDay());
    const [dataMN, setDataMN] = useState([]);
    const [imageMN, setImageMN] = useState([]);
    useEffect(() => {
        if (dateMT == 0) {
            if (currentHour < 18) {
                setDataMT(["khho", "kotu", "thth"]);
                setImageMT(["khanhhoa", "komtum", "hue"]);
            } else {
                setDataMT(["phye", "thth"]);
                setImageMT(["phuyen", "hue"]);
            }
        }
        if (dateMT == 1) {
            if (currentHour < 18) {
                setDataMT(["phye", "thth"]);
                setImageMT(["phuyen", "hue"]);
            } else {
                setDataMT(["dalak", "quna"]);
                setImageMT(["daklak", "quangnam"]);
            }
        }
        if (dateMT == 2) {
            if (currentHour < 18) {
                setDataMT(["dalak", "quna"]);
                setImageMT(["daklak", "quangnam"]);
            } else {
                setDataMT(["dana", "khho"]);
                setImageMT(["danang", "khanhhoa"]);
            }
        }
        if (dateMT == 3) {
            if (currentHour < 18) {
                setDataMT(["dana", "khho"]);
                setImageMT(["danang", "khanhhoa"]);
            } else {
                setDataMT(["qubi", "bidi", "qutr"]);
                setImageMT(["quangbinh", "binhdinh", "quangtri"]);
            }
        }
        if (dateMT == 4) {
            if (currentHour < 18) {
                setDataMT(["qubi", "bidi", "qutr"]);
                setImageMT(["quangbinh", "binhdinh", "quangtri"]);
            } else {
                setDataMT(["gila", "nith"]);
                setImageMT(["gialai", "ninhthuan"]);
            }
        }
        if (dateMT == 5) {
            if (currentHour < 18) {
                setDataMT(["gila", "nith"]);
                setImageMT(["gialai", "ninhthuan"]);
            } else {
                setDataMT(["dana", "qung", "dano"]);
                setImageMT(["danang", "quangngai", "daknong"]);
            }
        }
        if (dateMT == 6) {
            if (currentHour < 18) {
                setDataMT(["dana", "qung", "dano"]);
                setImageMT(["danang", "quangngai", "daknong"]);
            } else {
                setDataMT(["khho", "kotu", "thth"]);
                setImageMT(["khanhhoa", "komtum", "hue"]);
            }
        }
    }, [dateMT]);
    useEffect(() => {
        if (dateMN == 0) {
            if (currentHour < 17) {
                setDataMN(["dalat", "kigi", "tigi"]);
                setImageMN(["dalat", "kiengiang", "tiengiang"]);
            } else {
                setDataMN(["cama", "doth", "tphc"]);
                setImageMN(["camau", "dongthap", "tphcm"]);
            }
        }
        if (dateMN == 1) {
            if (currentHour < 17) {
                setDataMN(["cama", "doth", "tphc"]);
                setImageMN(["camau", "dongthap", "tphcm"]);
            } else {
                setDataMN(["bali", "vuta", "betr"]);
                setImageMN(["baclieu", "vungtau", "bentre"]);
            }
        }
        if (dateMN == 2) {
            if (currentHour < 17) {
                setDataMN(["bali", "vuta", "betr"]);
                setImageMN(["baclieu", "vungtau", "bentre"]);
            } else {
                setDataMN(["cath", "dona", "sotr"]);
                setImageMN(["cantho", "dongnai", "soctrang"]);
            }
        }
        if (dateMN == 3) {
            if (currentHour < 17) {
                setDataMN(["cath", "dona", "sotr"]);
                setImageMN(["cantho", "dongnai", "soctrang"]);
            } else {
                setDataMN(["angi", "bith", "tani"]);
                setImageMN(["angiang", "binhthuan", "tayninh"]);
            }
        }
        if (dateMN == 4) {
            if (currentHour < 17) {
                setDataMN(["angi", "bith", "tani"]);
                setImageMN(["angiang", "binhthuan", "tayninh"]);
            } else {
                setDataMN(["bidu", "trvi", "vilo"]);
                setImageMN(["binhduong", "travinh", "vinhlong"]);
            }
        }
        if (dateMN == 5) {
            if (currentHour < 17) {
                setDataMN(["bidu", "trvi", "vilo"]);
                setImageMN(["binhduong", "travinh", "vinhlong"]);
            } else {
                setDataMN(["biph", "hagi", "loan", "tphc"]);
                setImageMN(["binhphuoc", "haugiang", "longan", "tphcm"]);
            }
        }
        if (dateMN == 6) {
            if (currentHour < 17) {
                setDataMN(["biph", "hagi", "loan", "tphc"]);
                setImageMN(["binhphuoc", "haugiang", "longan", "tphcm"]);
            } else {
                setDataMN(["dalat", "kigi", "tigi"]);
                setImageMN(["dalat", "kiengiang", "tiengiang"]);
            }
        }
    }, [dateMN]);

    function isMobileDevice() {
        return /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent);
    }

    function launchGame(params) {
        if (profile === null) {
            navigate("/login");
            return false;
        }
        if (isLoading == true) return false;
        const form = {
            ...params,
            mobile: isMobileDevice == true ? 1 : 0
        };
        setLoading(true);
        axios
            .post(`${process.env.REACT_APP_API_URL}/casino/Seamless/LaunchGame`, form)
            .then((res) => {
                if (res.data.data.ErrorCode == 0) {
                    window.location.href = res.data.data.Url;
                } else {
                    swal("Sảnh đang bảo trì", "Vui lòng chọn sảnh khác", "error");
                }
                setLoading(false);
            })
            .catch((err) => {
                swal("Đã có lỗi xảy ra", err.response.data.message != "internal server error" ? err.response.data.message : "Vui lòng đăng xuất và đăng nhập lại", "error").then(
                    () => navigate("/login")
                );
                setLoading(false);
            });
    }
    const openGame = () => {
        if (!profile || profile?.username == "undefined") navigate("/");
        setLoading(true);
        fetch(`https://checksodu.sibobet.com/sodu.php?nickname=S${profile?.username}`)
            .then((response) => {
                if (!response.ok) {
                    console.log("Failed to fetch data");
                }
                return response.json();
            })
            .then((data) => {
                setBalance(Number(data.vin));
                setLoading(false);
            })
            .catch((error) => {
                setLoading(false);
            });
        setSiboGame(true);
    };
    const [isSubmitting, setIsSubmitting] = useState(false);

    const submitSibo = async () => {
        if (!profile || typeof profile.username === "undefined") {
            navigate("/");
            return;
        }
        if (isSubmitting) {
            swal("Thông báo", "Bạn thao tác quá nhanh", "warning");
            return; 
        }
        setLoading(true);
        const transactionAmount = Number(newMoney?.replaceAll(".", "")?.replaceAll(",", ""));
        if (transactionAmount < 0) {
            setLoading(false);
            swal("Chú ý", "Vui lòng nhập số tiền cần giao dịch", "warning");
            return;
        }
        try {
            setIsSubmitting(true);
            const response = await fetch(`https://checksodu.sibobet.com/sodu.php?nickname=S${profile.username}`);
            const data = await response.json();
            const oldNum = Number(data.vin);
            if (type === 2 && transactionAmount > oldNum) {
                setLoading(false);
                setIsSubmitting(false);
                swal("Thông báo", "Số dư trong Sibogame không đủ", "error");
                return; 
            }

            const formData = { money: transactionAmount, type, curr: balance };
            const res = await axios.post(`${process.env.REACT_APP_API_URL}/casino/sibomoney`, formData);

            setLoading(false);
            getProfile();
            setBalance(balance + Number(res.data.data));
            swal("Thành công", `Giao dịch ${type === 1 ? "nạp tiền" : "rút tiền"} ${newMoney} thành công`, "success");
        } catch (err) {
            setLoading(false);
            if (err.response && err.response.data && err.response.data.message) {
                swal("Thất bại", err.response.data.message !== "internal server error" ? err.response.data.message : "Đã có lỗi xảy ra", "error");
            } else {
                swal("Thất bại", "Không thể kết nối đến máy chủ, vui lòng thử lại sau", "error");
            }
        } finally {
            setTimeout(() => {
                setIsSubmitting(false);
            }, 10000);
        }
    };
    return (
        <>
            {isLoading ? (
                <div className="loading">
                    <div className="loader"></div>
                </div>
            ) : null}
            <Header profile={profile} />
            {swiperParams && notify && bet?.issueList && (
                <AnimatedPage>
                    <div className="box-slide" style={{ overflow: "hidden" }}>
                        <Swiper {...swiperParams}>
                            {notify.map((item, index) => (
                                <>
                                    {index > 1 && item.title !== "marquee" && item.isShow === true ? (
                                        <SwiperSlide>
                                            <img alt={item.title.replace("}", "").replace("{", "")} src={item.image} />
                                        </SwiperSlide>
                                    ) : null}
                                </>
                            ))}
                        </Swiper>
                        {notify ? (
                            <>
                                {notify[0].isShow === true && notify[0].title === "marquee" ? (
                                    <div className="marquees">
                                        <div>
                                            <CampaignIcon sx={{ fontSize: "22px" }} />
                                        </div>
                                        <div>
                                            <div
                                                className="chaychu"
                                                style={{ animation: "chuchay " + notify[0].content.length / 10 + "s linear infinite" }}
                                                dangerouslySetInnerHTML={{
                                                    __html: notify[0].content
                                                }}
                                            />
                                        </div>
                                    </div>
                                ) : null}
                            </>
                        ) : null}
                    </div>
                    <Swiper {...swiperMenu} className="swiper-menu-game">
                        <SwiperSlide>
                            <div className={activeOption === "1" ? "active" : ""} onClick={() => handleOptionClick("1")}>
                                {activeOption === "1" ? <img alt="" src="/newimg/icon_dacsac2.png" /> : <img alt="" src="/newimg/icon_dacsac.png" />}
                            </div>
                        </SwiperSlide>
                        <SwiperSlide>
                            <div className={activeOption === "2" ? "active" : ""} onClick={() => handleOptionClick("2")}>
                                {activeOption === "2" ? <img alt="" src="/newimg/icon_xoso2.png" /> : <img alt="" src="/newimg/icon_xoso.png" />}
                            </div>
                        </SwiperSlide>
                        <SwiperSlide>
                            <div className={activeOption === "3" ? "active" : ""} onClick={() => handleOptionClick("3")}>
                                {activeOption === "3" ? <img alt="" src="/newimg/icon_casino2.png" /> : <img alt="" src="/newimg/icon_casino.png" />}
                            </div>
                        </SwiperSlide>
                        <SwiperSlide>
                            <div className={activeOption === "4" ? "active" : ""} onClick={() => handleOptionClick("4")}>
                                {activeOption === "4" ? <img alt="" src="/newimg/icon_sibobet2.png" /> : <img alt="" src="/newimg/icon_sibobet.png" />}
                            </div>
                        </SwiperSlide>
                        <SwiperSlide>
                            <div className={activeOption === "5" ? "active" : ""} onClick={() => handleOptionClick("5")}>
                                {activeOption === "5" ? <img alt="" src="/newimg/icon_nohu2.png" /> : <img alt="" src="/newimg/icon_nohu.png" />}
                            </div>
                        </SwiperSlide>
                        <SwiperSlide>
                            <div className={activeOption === "10" ? "active" : ""} onClick={() => handleOptionClick("10")}>
                                {activeOption === "10" ? <img alt="" src="/newimg/icon_xocdia2.png" /> : <img alt="" src="/newimg/icon_xocdia.png" />}
                            </div>
                        </SwiperSlide>
                        <SwiperSlide>
                            <div className={activeOption === "6" ? "active" : ""} onClick={() => handleOptionClick("6")}>
                                {activeOption === "6" ? <img alt="" src="/newimg/icon_thethao2.png" /> : <img alt="" src="/newimg/icon_thethao.png" />}
                            </div>
                        </SwiperSlide>
                        <SwiperSlide>
                            <div className={activeOption === "7" ? "active" : ""} onClick={() => handleOptionClick("7")}>
                                {activeOption === "7" ? <img alt="" src="/newimg/icon_banca2.png" /> : <img alt="" src="/newimg/icon_banca.png" />}
                            </div>
                        </SwiperSlide>
                        <SwiperSlide>
                            <div className={activeOption === "8" ? "active" : ""} onClick={() => handleOptionClick("8")}>
                                {activeOption === "8" ? <img alt="" src="/newimg/icon_gamebai2.png" /> : <img alt="" src="/newimg/icon_gamebai.png" />}
                            </div>
                        </SwiperSlide>
                        <SwiperSlide>
                            <div className={activeOption === "9" ? "active" : ""} onClick={() => handleOptionClick("9")}>
                                {activeOption === "9" ? <img alt="" src="/newimg/icon_daga2.png" /> : <img alt="" src="/newimg/icon_daga.png" />}
                            </div>
                        </SwiperSlide>
                    </Swiper>
                    <div style={{ padding: "0 0.32rem 2rem" }}>
                        <div className="content-game">
                            {activeOption === "1" && (
                                <div className="list-game">
                                    <img src="/newimg/img_title_xshot.png" class="img_title" style={{ marginTop: "0.32rem", marginBottom: "0.2rem" }} />
                                    <div className="box-miba miba-pro">
                                        <div className="box-miba-left" onClick={() => navigate("/xsmb/lo2")}>
                                            <img alt="" src={require("../../images/3mien/mienbac.png")} />
                                        </div>
                                        <div className="box-miba-right">
                                            <div className="box-text" style={{ fontWeight: "bold" }}>
                                                Miền Bắc <img alt="" src={require("../../img/icon-hot.png")} className="icon-hot" />
                                            </div>
                                            <div className="box-text" style={{ background: "#293356!important", padding: "0.32rem 0.32rem" }}>
                                                Ngày: {bet?.issueList[0]?.turnNum}
                                            </div>
                                            <div className="box-miba-kq" style={{ padding: "0" }}>
                                                {bet?.issueList[0]?.openNum.split(",").map((x) => (
                                                    <div className="ball">
                                                        <img alt="" src={`/images/ball/${x}.png`} />
                                                    </div>
                                                ))}
                                            </div>
                                        </div>
                                    </div>
                                    <img src="/newimg/img_title_thethao.png" class="img_title" />
                                    <Swiper {...swiperGame}>
                                        <SwiperSlide>
                                            <div className="api-game" onClick={() => launchGame({ productid: "1012", gametype: "3" })}>
                                                <img alt="" src="/newimg/sports_sportsbook_home.png" />
                                            </div>
                                        </SwiperSlide>
                                        <SwiperSlide>
                                            <div className="api-game" onClick={() => launchGame({ productid: "1046", gametype: "3" })}>
                                                <img alt="" src="/newimg/sports_sabasports_home.png" />
                                            </div>
                                        </SwiperSlide>
                                        <SwiperSlide>
                                            <div className="api-game" onClick={() => launchGame({ productid: "1078", gametype: "3" })}>
                                                <img alt="" src="/newimg/sports_home_cmd368.png" />
                                            </div>
                                        </SwiperSlide>
                                    </Swiper>
                                    <img src="/newimg/img_title_sibogame.png" class="img_title" style={{ marginBottom: "0" }} />
                                    <Swiper {...swiperGame2}>
                                        <SwiperSlide>
                                            <div className="api-game" onClick={openGame}>
                                                <img alt="" src="/newimg/dx_tx.png" />
                                            </div>
                                        </SwiperSlide>
                                        <SwiperSlide>
                                            <div className="api-game" onClick={openGame}>
                                                <img alt="" src="/newimg/dx_baucua.png" />
                                            </div>
                                        </SwiperSlide>
                                        <SwiperSlide>
                                            <div className="api-game" onClick={openGame}>
                                                <img alt="" src="/newimg/dx_thantai.png" />
                                            </div>
                                        </SwiperSlide>
                                        <SwiperSlide>
                                            <div className="api-game" onClick={openGame}>
                                                <img alt="" src="/newimg/dx_maubinh.png" />
                                            </div>
                                        </SwiperSlide>
                                        <SwiperSlide>
                                            <div className="api-game" onClick={openGame}>
                                                <img alt="" src="/newimg/dx_phom.png" />
                                            </div>
                                        </SwiperSlide>
                                        <SwiperSlide>
                                            <div className="api-game" onClick={openGame}>
                                                <img alt="" src="/newimg/dx_thantai777.png" />
                                            </div>
                                        </SwiperSlide>
                                        <SwiperSlide>
                                            <div className="api-game" onClick={openGame}>
                                                <img alt="" src="/newimg/dx_banca.png" />
                                            </div>
                                        </SwiperSlide>
                                        <SwiperSlide>
                                            <div className="api-game" onClick={openGame}>
                                                <img alt="" src="/newimg/dx_f35.png" />
                                            </div>
                                        </SwiperSlide>
                                        <SwiperSlide>
                                            <div className="api-game" onClick={openGame}>
                                                <img alt="" src="/newimg/dx_trenduoi.png" />
                                            </div>
                                        </SwiperSlide>
                                    </Swiper>
                                    <img src="/newimg/img_title_casino.png" class="img_title" />
                                    <Swiper {...swiperGame}>
                                        <SwiperSlide>
                                            <div className="api-game" onClick={() => launchGame({ productid: "1020", gametype: "2" })}>
                                                <img alt="" src="/newimg/live_wmlive_home.png" />
                                            </div>
                                        </SwiperSlide>
                                        <SwiperSlide>
                                            <div className="api-game" onClick={() => launchGame({ productid: "1022", gametype: "2" })}>
                                                <img alt="" src="/newimg/live_aelive_home.png" />
                                            </div>
                                        </SwiperSlide>
                                        <SwiperSlide>
                                            <div className="api-game" onClick={() => launchGame({ productid: "1052", gametype: "2" })}>
                                                <img alt="" src="/newimg/live_dg_home.png" />
                                            </div>
                                        </SwiperSlide>
                                        {/*<SwiperSlide>
                                            <div className="api-game" onClick={() => launchGame({ productid: "1002", gametype: "2" })}>
                                                <img alt="" src="/newimg/live_aglive_home.png" />
                                            </div>
                                                </SwiperSlide>*/}
                                        <SwiperSlide>
                                            <div className="api-game" onClick={() => launchGame({ productid: "1002", gametype: "2" })}>
                                                <img alt="" src="/newimg/live_home_evolution.png" />
                                            </div>
                                        </SwiperSlide>
                                    </Swiper>
                                    <img src="/newimg/img_title_slots.png" class="img_title" />
                                    <Swiper {...swiperGame}>
                                        <SwiperSlide>
                                            <div className="api-game" onClick={() => launchGame({ productid: "1091", gametype: "1" })}>
                                                <img alt="" src={require("../../images/games/jili.png")} />
                                            </div>
                                        </SwiperSlide>
                                        <SwiperSlide>
                                            <div className="api-game" onClick={() => launchGame({ productid: "1085", gametype: "1" })}>
                                                <img alt="" src={require("../../images/games/jdb.png")} />
                                            </div>
                                        </SwiperSlide>
                                        <SwiperSlide>
                                            <div className="api-game" onClick={() => launchGame({ productid: "1004", gametype: "1" })}>
                                                <img alt="" src={require("../../images/games/mg.png")} />
                                            </div>
                                        </SwiperSlide>
                                        <SwiperSlide>
                                            <div className="api-game" onClick={() => launchGame({ productid: "1007", gametype: "1" })}>
                                                <img alt="" src={require("../../images/games/pg.png")} />
                                            </div>
                                        </SwiperSlide>
                                        <SwiperSlide>
                                            <div className="api-game" onClick={() => launchGame({ productid: "1006", gametype: "1" })}>
                                                <img alt="" src={require("../../images/games/pp.png")} />
                                            </div>
                                        </SwiperSlide>
                                        <SwiperSlide>
                                            <div className="api-game" onClick={() => launchGame({ productid: "1009", gametype: "1" })}>
                                                <img alt="" src={require("../../images/games/cq9.png")} />
                                            </div>
                                        </SwiperSlide>
                                        <SwiperSlide>
                                            <div className="api-game" onClick={() => launchGame({ productid: "1011", gametype: "1" })}>
                                                <img alt="" src={require("../../images/games/pt.png")} />
                                            </div>
                                        </SwiperSlide>
                                    </Swiper>
                                    <img src="/newimg/img_title_banca.png" class="img_title" />
                                    <Swiper {...swiperGame3}>
                                        <SwiperSlide>
                                            <div className="api-game" onClick={() => launchGame({ productid: "1004", gametype: "8" })}>
                                                <img alt="" src="/newimg/fish_mgfish_main.png" />
                                            </div>
                                        </SwiperSlide>
                                        <SwiperSlide>
                                            <div className="api-game" onClick={() => launchGame({ productid: "1091", gametype: "8" })}>
                                                <img alt="" src="/newimg/fish_jilifish_main.png" />
                                            </div>
                                        </SwiperSlide>
                                        <SwiperSlide>
                                            <div className="api-game" onClick={() => launchGame({ productid: "1009", gametype: "8" })}>
                                                <img alt="" src="/newimg/fish_cq9fish_main.png" />
                                            </div>
                                        </SwiperSlide>
                                    </Swiper>
                                    <img src="/newimg/img_title_gamebai.png" class="img_title" />
                                    <Swiper {...swiperGame}>
                                        <SwiperSlide>
                                            <div className="api-game" onClick={() => launchGame({ provider: "TP", type: "CB" })}>
                                                <img alt="" src="/newimg/chess_home_tp.png" />
                                            </div>
                                        </SwiperSlide>
                                        <SwiperSlide>
                                            <div className="api-game" onClick={() => launchGame({ productid: "1076", gametype: "7" })}>
                                                <img alt="" src="/newimg/chess_home_v8.png" />
                                            </div>
                                        </SwiperSlide>
                                        <SwiperSlide>
                                            <div className="api-game" onClick={() => launchGame({ provider: "KY", type: "CB" })}>
                                                <img alt="" src="/newimg/chess_home_ky.png" />
                                            </div>
                                        </SwiperSlide>
                                    </Swiper>
                                </div>
                            )}
                            {activeOption === "2" && (
                                <div className="list-game" style={{ marginTop: "20px" }}>
                                    <div className="box-miba">
                                        <div className="box-miba-left" onClick={() => navigate("/xsmb/lo2")}>
                                            <img alt="" src={require("../../images/3mien/mienbac.png")} />
                                            <div className="dat-cuoc" onClick={() => navigate("/xsmb/lo2")}>
                                                Đặt cược
                                            </div>
                                        </div>
                                        <div className="box-miba-right">
                                            <div className="box-text">
                                                Miền Bắc <img alt="" src={require("../../img/icon-hot.png")} className="icon-hot" />
                                            </div>
                                            <div className="box-text">Ngày: {bet?.issueList[0]?.turnNum}</div>
                                            <div className="box-miba-kq">
                                                {bet?.issueList[0]?.openNum.split(",").map((x) => (
                                                    <div className="ball">
                                                        <img alt="" src={`/images/ball/${x}.png`} />
                                                    </div>
                                                ))}
                                            </div>
                                        </div>
                                    </div>
                                    {/*<div style={{ display: "grid", gridTemplateColumns: "1fr 1fr" }}>
                                        <div>
                                            <div className="title" style={{ textAlign: "center" }}>
                                                Xổ số nhanh
                                            </div>
                                            <div className="list-game-xs">
                                                <Link to="/xs75s/lo2" style={{ width: "50%" }}>
                                                    <img alt="" src={require(`../../images/mb75s.png`)} />
                                                </Link>
                                                <Link to="/xs120s/lo2" style={{ width: "50%" }}>
                                                    <img alt="" src={require(`../../images/st2p.png`)} />
                                                </Link>
                                            </div>
                                        </div>
                                        <div>
                                            <div className="title" style={{ textAlign: "center" }}>
                                                Xóc đĩa
                                            </div>
                                            <div className="list-game-xs">
                                                <Link to="/xd45s" style={{ width: "50%" }}>
                                                    <img alt="" src={require(`../../images/xd45s.png`)} />
                                                </Link>
                                                <Link to="/xd60s" style={{ width: "50%" }}>
                                                    <img alt="" src={require(`../../images/xd1p.png`)} />
                                                </Link>
                                            </div>
                                        </div>
                                    </div>*/}
                                    <div className="title" onClick={() => navigate("/xoso")}>
                                        Miền Nam >>
                                    </div>
                                    <div className="list-game-xs">
                                        {dataMN?.map((item, index) => (
                                            <>
                                                <Link to={`/xsmn/lo2/${item}`} key={item}>
                                                    <img alt="" src={require(`../../images/3mien/${imageMN[index]}.png`)} />
                                                </Link>
                                            </>
                                        ))}
                                    </div>
                                    <div className="title" onClick={() => navigate("/xoso")}>
                                        Miền Trung >>
                                    </div>
                                    <div className="list-game-xs">
                                        {dataMT?.map((item, index) => (
                                            <>
                                                <Link to={`/xsmt/lo2/${item}`} key={item}>
                                                    <img alt="" src={require(`../../images/3mien/${imageMT[index]}.png`)} />
                                                </Link>
                                            </>
                                        ))}
                                    </div>
                                </div>
                            )}
                            {activeOption === "3" && (
                                <>
                                    <div className="api-game" onClick={() => launchGame({ productid: "1020", gametype: "2" })}>
                                        <img alt="" src={require("../../images/game/WM.JPG")} style={{ borderRadius: "18px" }} />
                                        <img alt="" src={require("../../images/game/hot.gif")} className="sub_hot" />
                                    </div>
                                    <div className="api-game" onClick={() => launchGame({ productid: "1052", gametype: "2" })}>
                                        <img alt="" src={require("../../images/game/DG.JPG")} style={{ borderRadius: "18px" }} />
                                        <img alt="" src={require("../../images/game/hot.gif")} className="sub_hot" />
                                    </div>
                                    {/*<div className="api-game" onClick={() => launchGame({ productid: "1002", gametype: "2" })}>
                                        <img alt="" src={require("../../images/game/AG.JPG")} style={{ borderRadius: "18px" }} />
                                    </div>*/}
                                    <div className="api-game" onClick={() => launchGame({ productid: "1022", gametype: "2" })}>
                                        <img alt="" src={require("../../images/game/SEXY.JPG")} style={{ borderRadius: "18px" }} />
                                        <img alt="" src={require("../../images/game/hot.gif")} className="sub_hot" />
                                    </div>
                                    <div className="api-game" onClick={() => launchGame({ productid: "1003", gametype: "2" })}>
                                        <img alt="" src={require("../../images/game/ALLBET.JPG")} style={{ borderRadius: "18px" }} />
                                    </div>
                                    <div className="api-game" onClick={() => launchGame({ productid: "1002", gametype: "2" })}>
                                        <img alt="" src={require("../../images/game/evo.png")} style={{ borderRadius: "18px" }} />
                                    </div>
                                </>
                            )}
                            {activeOption === "4" && (
                                <>
                                    <div class="api-game-flex">
                                        <div className="api-game" style={{ width: "33.33%" }} onClick={openGame}>
                                            <img alt="" src="/newimg/dx_tx.png" />
                                        </div>

                                        <div className="api-game" style={{ width: "33.33%" }} onClick={openGame}>
                                            <img alt="" src="/newimg/dx_baucua.png" />
                                        </div>

                                        <div className="api-game" style={{ width: "33.33%" }} onClick={openGame}>
                                            <img alt="" src="/newimg/dx_thantai.png" />
                                        </div>

                                        <div className="api-game" style={{ width: "33.33%" }} onClick={openGame}>
                                            <img alt="" src="/newimg/dx_maubinh.png" />
                                        </div>

                                        <div className="api-game" style={{ width: "33.33%" }} onClick={openGame}>
                                            <img alt="" src="/newimg/dx_phom.png" />
                                        </div>

                                        <div className="api-game" style={{ width: "33.33%" }} onClick={openGame}>
                                            <img alt="" src="/newimg/dx_thantai777.png" />
                                        </div>

                                        <div className="api-game" style={{ width: "33.33%" }} onClick={openGame}>
                                            <img alt="" src="/newimg/dx_banca.png" />
                                        </div>

                                        <div className="api-game" style={{ width: "33.33%" }} onClick={openGame}>
                                            <img alt="" src="/newimg/dx_f35.png" />
                                        </div>

                                        <div className="api-game" style={{ width: "33.33%" }} onClick={openGame}>
                                            <img alt="" src="/newimg/dx_trenduoi.png" />
                                        </div>
                                    </div>
                                </>
                            )}
                            {activeOption === "5" && (
                                <div class="api-game-flex">
                                    <div className="api-game" onClick={() => launchGame({ productid: "1091", gametype: "1" })}>
                                        <img alt="" src={require("../../images/games/jili.png")} />
                                    </div>
                                    <div className="api-game" onClick={() => launchGame({ productid: "1085", gametype: "1" })}>
                                        <img alt="" src={require("../../images/games/jdb.png")} />
                                    </div>
                                    <div className="api-game" onClick={() => launchGame({ productid: "1007", gametype: "1" })}>
                                        <img alt="" src={require("../../images/games/pg.png")} />
                                    </div>
                                    <div className="api-game" onClick={() => launchGame({ productid: "1006", gametype: "1" })}>
                                        <img alt="" src={require("../../images/games/pp.png")} />
                                    </div>
                                    <div className="api-game" onClick={() => launchGame({ productid: "1009", gametype: "1" })}>
                                        <img alt="" src={require("../../images/games/cq9.png")} />
                                    </div>
                                    <div className="api-game" onClick={() => launchGame({ productid: "1011", gametype: "1" })}>
                                        <img alt="" src={require("../../images/games/pt.png")} />
                                    </div>
                                </div>
                            )}
                            {activeOption === "6" && (
                                <>
                                    <div className="api-game" onClick={() => launchGame({ productid: "1046", gametype: "3" })}>
                                        <img alt="" src={require("../../images/game/SABA.JPG")} style={{ borderRadius: "18px" }} />
                                        <img alt="" src={require("../../images/game/hot.gif")} className="sub_hot" />
                                    </div>
                                    <div className="api-game" onClick={() => launchGame({ productid: "1012", gametype: "3" })}>
                                        <img alt="" src={require("../../images/game/SBO.JPG")} style={{ borderRadius: "18px" }} />
                                        <img alt="" src={require("../../images/game/hot.gif")} className="sub_hot" />
                                    </div>
                                    <div className="api-game" onClick={() => launchGame({ productid: "1036", gametype: "3" })}>
                                        <img alt="" src={require("../../images/game/UG.JPG")} style={{ borderRadius: "18px" }} />
                                    </div>
                                    <div className="api-game" onClick={() => launchGame({ productid: "1078", gametype: "3" })}>
                                        <img alt="" src={require("../../images/game/CMD.JPG")} style={{ borderRadius: "18px" }} />
                                    </div>
                                </>
                            )}
                            {activeOption === "7" && (
                                <>
                                    <div className="api-game" onClick={() => launchGame({ productid: "1091", gametype: "8" })}>
                                        <img alt="" src={require("../../images/games/fish_jilifish.png")} />
                                        <img alt="" src={require("../../images/game/hot.gif")} className="sub_hot" />
                                    </div>
                                    <div className="api-game" onClick={() => launchGame({ productid: "1009", gametype: "8" })}>
                                        <img alt="" src={require("../../images/games/fish_cq9fish.png")} />
                                    </div>
                                    <div className="api-game" onClick={() => launchGame({ productid: "1004", gametype: "8" })}>
                                        <img alt="" src={require("../../images/games/fish_mgfish.png")} />
                                    </div>
                                </>
                            )}
                            {activeOption === "8" && (
                                <>
                                    <div className="api-game" onClick={() => launchGame({ productid: "1076", gametype: "7" })}>
                                        <img alt="" src={require("../../images/games/chess_sub_v8.png")} />
                                    </div>
                                    <div className="api-game" onClick={() => launchGame({ provider: "TP", type: "CB" })}>
                                        <img alt="" src={require("../../images/games/gamebai_tp.png")} />
                                    </div>
                                    <div className="api-game" onClick={() => launchGame({ provider: "KY", type: "CB" })}>
                                        <img alt="" src={require("../../images/games/ky.png")} />
                                    </div>
                                </>
                            )}
                            {activeOption === "9" && (
                                <>
                                    <div className="api-game">
                                        <img alt="" src="/newimg/img_daga.png" style={{ borderRadius: "18px" }} />
                                        <img alt="" src={require("../../images/game/hot.gif")} className="sub_hot" />
                                    </div>
                                </>
                            )}
                            {activeOption === "10" && (
                                <>
                                    <div class="api-game-flex">
                                        <div style={{ width: "33.33%" }} className="api-game" onClick={() => launchGame({ productid: "1020", gametype: "2" })}>
                                            <img alt="" src={require("../../images/games/dice.png")} />
                                            <img alt="" src={require("../../images/game/logo_wm.png")} className="sub_logo" />
                                        </div>
                                        <div style={{ width: "33.33%" }} className="api-game" onClick={() => launchGame({ productid: "1020", gametype: "2" })}>
                                            <img alt="" src={require("../../images/games/sd.png")} />
                                            <img alt="" src={require("../../images/game/logo_wm.png")} className="sub_logo" />
                                        </div>
                                        <div style={{ width: "33.33%" }} className="api-game" onClick={() => launchGame({ productid: "1020", gametype: "2" })}>
                                            <img alt="" src={require("../../images/games/slotLs.png")} />
                                            <img alt="" src={require("../../images/game/logo_wm.png")} className="sub_logo" />
                                        </div>
                                    </div>
                                    {/*<div class="api-game-flex">
                                        <Link style={{ width: "33.33%" }} className="api-game" to="/xd60s">
                                            <img alt="" src={require("../../images/xd1p.png")} />
                                        </Link>
                                        <Link style={{ width: "33.33%" }} className="api-game" to="/xd45s">
                                            <img alt="" src={require("../../images/xd45s.png")} />
                                        </Link>
                                    </div>*/}
                                </>
                            )}
                            {/*activeOption !== "1" && (
                                <div className="baohanh">
                                    <img alt="" src={require("../../images/bao-hanh-va-sua-chua.png")} style={{ maxWidth: "8rem" }} />
                                    <div className="nangcap">Hệ thống đang nâng cấp...</div>
                                </div>
                            )*/}
                        </div>
                    </div>
                </AnimatedPage>
            )}
            <Footer />
            {profile && notify && isShow && notifyParam != null && (
                <div className="popup-backdrop">
                    <div className="popup-main">
                        <div className="popup-times" onClick={() => setShow(false)}>
                            &times;
                        </div>
                        <div className="popup-content" style={{ padding: "20px" }}>
                            {notify.map(
                                (item) =>
                                    item.isShow &&
                                    item.title === "popup" && (
                                        <>
                                            {item.content.includes("{") && item.content.includes("}") ? (
                                                <img
                                                    src={item.content.substring(item.content.indexOf("{") + 1, item.content.indexOf("}"))}
                                                    style={{ width: "100%", height: "auto" }}
                                                />
                                            ) : (
                                                <div dangerouslySetInnerHTML={{ __html: item.content }} />
                                            )}
                                        </>
                                    )
                            )}
                        </div>
                    </div>
                </div>
            )}
            {siboGame === true && profile ? (
                <div className="popup-backdrop">
                    <div className="popup-main">
                        <div className="popup-close-top" onClick={() => setSiboGame(false)}>
                            <CloseIcon sx={{ fontSize: "26px" }} />
                        </div>
                        <div className="popup-header">Sibogame</div>
                        <div className="popup-content">
                            <ul className="tab-navigation tab-game">
                                <li className={type === 1 ? "active" : ""} onClick={() => handleType(1)}>
                                    Nạp tiền
                                </li>
                                <li className={type === 2 ? "active" : ""} onClick={() => handleType(2)}>
                                    Rút tiền
                                </li>
                            </ul>
                            <div className="form-sibo">
                                <input
                                    type="text"
                                    value={newMoney}
                                    onClick={() => setNewMoney(null)}
                                    onChange={(e) => setNewMoney(Number(e.target.value.replaceAll(".", "").replaceAll(",", "")).toLocaleString("vi-VN"))}
                                    placeholder="Nhập số tiền"
                                />
                                <button onClick={submitSibo}>Xác nhận</button>
                            </div>
                            <div style={{ textAlign: "center", margin: "10px 0 0" }}>
                                Số dư sibo: <b>{balance?.toLocaleString("vi-VN")}</b>
                            </div>
                            <div style={{ textAlign: "center", margin: "10px 0 0" }}>
                                Số dư tài khoản: <b>{profile?.money?.toLocaleString("vi-VN")}</b>
                            </div>
                            <button
                                className="open-game"
                                onClick={() =>
                                    (window.location.href = `https://sibogame.sibobet.com/indexGame.html?un=${profile?.username}&pw=${localStorage.getItem("password")}`)
                                }>
                                Vào game
                            </button>
                        </div>
                    </div>
                </div>
            ) : null}
        </>
    );
}
export default Home;
