import React from "react";
import { Link, useNavigate } from "react-router-dom";
import SettingsIcon from "@mui/icons-material/Settings";
import NotificationsIcon from "@mui/icons-material/Notifications";

const Header = ({ profile }) => {
    const navigate = useNavigate();
    return (
        <div className="header">
            <div className="logo">
                <Link to="/">
                    <img alt="" src="/images/sibologo.png" />
                </Link>
            </div>
            <div className="header-right">
                {profile ? (
                    <>
                        <div className="header-profile">
                            <span>{profile?.username}</span>
                            {Math.floor(profile?.money).toLocaleString('vi-VN')} <small>₫</small>
                        </div>
                        <div style={{ display: "flex" }}>
                            <NotificationsIcon sx={{ fontSize: "0.65rem", margin: "auto 0.25rem auto 0.35rem" }} onClick={() => navigate("/notification")} />
                        </div>
                        <div style={{ display: "flex" }}>
                            <SettingsIcon sx={{ fontSize: "0.65rem", margin: "auto" }} onClick={() => navigate("/profile")} />
                        </div>
                    </>
                ) : (
                    <>
                        <Link to="/login" className="btn-login" style={{ marginBottom: "0" }}>
                            Đăng nhập
                        </Link>
                        <Link to="/register" className="btn-register" style={{ marginBottom: "0" }}>
                            Đăng ký
                        </Link>
                    </>
                )}
            </div>
        </div>
    );
};

export default Header;
